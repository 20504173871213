import React,{useState} from 'react'
import { useTranslation } from 'react-i18next'; //语言包
import { connect } from 'react-redux'
import Joyride from 'react-joyride';
interface IProps {
  run: boolean;
  onSetStatus: any;
}

const Tour = (props: IProps) => {
  const { t } = useTranslation();
  const { run = true, onSetStatus } = props;

  const [steps]= useState([{
    target: '.registerPs',
    content: t('Common.Header.tour.tips1'),
    disableBeacon: true,
    hideCloseButton: true,
  },
  {
    target: '.deviceManagement',
    content: t('Common.Header.tour.tips2'),
  }]);

  const handleJoyrideCallback = (data) => {
  
    const { action } = data;
    switch (action) {
      case 'stop':
        props.handleBack('skip') 
        break;
      case 'back':
        break;
      case 'close':
        break;
      case 'last':
        props.handleBack('skip') 
        break;
      case 'next':
        props.handleBack(action)       
        break;
      case 'skip':
        props.handleBack(action)       
        break;
      case 'reset':
        onSetStatus(false);
        break;
      default:
        break;
    }
  };

  
  return (
    <>
    {/* { JSON.stringify(props.steps)+''} */}
      <Joyride
        steps={steps}
        continuous={true}
        locale={{ back: t('Common.Header.tour.tips3'), close: null, last: t('Common.Header.tour.tips4'),  skip: t('Common.Header.tour.tips5'), next: t('Common.Header.tour.tips6') }}
        styles={{
          options: {
            primaryColor: '#2F54EB',
            textColor: '#000',
            // arrowColor: '#e3ffeb',
            // backgroundColor: '#e3ffeb',
            // overlayColor: 'rgba(79, 26, 0, 0.4)',
            // primaryColor: '#000',
            // textColor: '#004a14',
            // width: 600,
            zIndex: 1000,
          },
        }}
        hideCloseButton={true}
        showProgress={false}
        showSkipButton={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        run={run}
        callback={handleJoyrideCallback}
      />
    </>

  )
}

const mapStateToProps = (state) => {
  return {

  }
}
const mapDippathToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDippathToProps)(Tour);