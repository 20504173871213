import styled from 'styled-components'

export const RegisterWrapper=styled.div`
    min-height: 254px;

    padding: 24px;
    background: var(--white-100);

    .ant-space {
        text-align: center !important;
        &.tip {
            gap: 0 !important;
            margin-bottom: 24px;

            .anticon {
                margin-right: 4px;
            }
            .ant-typography.ant-typography-secondary {
                font-size:  var(--fontsize-12);
                margin-left: 18px;
            }
            
            .ant-space-item {
                &:nth-child(1) {
                    .ant-typography {}
                }

                &:nth-child(2) {
                    .ant-typography {
                        font-size:  var(--fontsize-12);
                    }
                }
            }
        }
        &.checked {
            margin: 24px 0;

            .ant-divider-inner-text {
                display: flex;
                justify-content: center !important;
                flex-wrap: wrap !important;
                padding-left: 16px !important;
            }

            .ant-divider.ant-divider-vertical {
                display: none;
            }

            .ant-divider.ant-divider-with-text::before,
            .ant-divider.ant-divider-with-text::after {
                top: 50% !important;
                width: 50% !important;
            }

            .ant-space-item:nth-child(1) {
                height: 32px;
                overflow: hidden;
                .ant-typography {
                    &:nth-child(1) {
                        font-size:  var(--fontsize-16);
                        margin-top: 4px;
                    }
                }
            }
            .sweet {
                width: 32px;
                padding: 0;
                margin: 0;
                left: -28px;
                .sa-icon {
                    transform: scale(0.35);
                    margin: 0;
                    padding: 0;
                    
                    margin: -28px 8px -12px -28px;
                }
            }

             /* 输入框样式 */
            .ant-input-affix-wrapper>.ant-input,  
            .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
                height: 43px;

                letter-spacing: var(--spacing-large);

                font-size:  var(--fontsize-16) !important;
                padding: 6px 8px 6px 0 !important;
                box-sizing: border-box;
            }

            .ant-input-affix-wrapper:hover>.ant-input,
            .ant-input-affix-wrapper.ant-input-affix-wrapper-focused>.ant-input,  
            .ant-form-item-has-error .ant-input-affix-wrapper-status-error .ant-input {
                padding: 6px 8px 6px 3px !important;
            }

            .ant-input-affix-wrapper.ant-input-affix-wrapper-focused>.ant-input,
            .ant-form-item-has-error .ant-input-affix-wrapper-status-error .ant-input {
                font-size:  var(--fontsize-14) !important;
            }

            .ant-input-affix-wrapper.ant-input-affix-wrapper-focused>.ant-input,
            .ant-form-item-has-error .ant-input {
                letter-spacing: var(--spacing-normal) !important;
            }

            .ant-input-prefix {
                opacity: 0;

                color: var(--sky-55);
                margin-left: -20px;
                transition: var(--transition-normal);
            }

            .ant-input-suffix .ant-input-show-count-suffix,
            .ant-input-textarea-show-count::after {
                opacity: 0;
                transition: var(--transition-normal);
            }

            .ant-input-affix-wrapper:hover .ant-input-prefix,
            .ant-input-affix-wrapper-focused .ant-input-prefix,
            .ant-form-item-has-error .ant-input-prefix {
                opacity: 1;
                margin-left: 0;
                transition: var(--transition-normal);
            }

            .ant-input-affix-wrapper-focused .ant-input-suffix .ant-input-show-count-suffix,
            .ant-form-item-has-error .ant-input-suffix .ant-input-show-count-suffix,
            .ant-input-affix-wrapper-focused .ant-input-textarea-show-count::after {
                opacity: 1;
            }

            .ant-input-affix-wrapper-focused .ant-input-prefix {
                color: var(--sky-100);
                padding-left: 8px;
            }

            .ant-form-item-has-error .ant-input-prefix {
                color: var(--tomato-35);
                padding-left: 8px;
            }

            .ant-input-affix-wrapper {
                color: var(--font-85);
                border-color: transparent !important;
                background-color: transparent !important;
                padding: 0 8px 0 0 !important;
                resize: none;
            }

            .ant-input-affix-wrapper:hover {
                background-color: var(--white-35) !important;
            }
            .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
                border-color: transparent !important;
            }

            .ant-input-affix-wrapper-focused {
                border-color: #40a9ff !important;
                background-color: var(--white-75) !important;
            }

            .ant-input.ant-input-status-error,
            .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
                border-color: var(--tomato-35) !important;
                background-color: var(--white-100) !important;
            }
        }
    }

    .ant-input-group.ant-input-group-compact {
        max-width: 480px;
        margin: 0 auto;
        .ant-form-item {
            width: calc(100% - 80px);
            margin-bottom: 0;
        }
        .ant-btn {
            width: 80px;
            height: 52px;

            border-radius: 0 8px 8px 0 !important;

            &.ant-btn-primary {
                box-shadow: none !important;
            }
        }
        .ant-input-affix-wrapper {
            text-align: left !important;
            border-radius: 6px 0 0 6px !important;
            padding: 8px !important;
        }
    }
`

export const AddPsBtn=styled.div`
  .ant-btn.registerPs {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center !important;
    flex-wrap: nowrap;

    padding: 0 8px !important;
    margin-right: 8px !important;
    box-sizing: border-box;

    border: 1px solid var(--black-0);
    border-right-color: var(--black-585);
    border-radius: 0 !important;
    background: var(--white-100) !important;

    font-size:  var(--fontsize-12) !important;

    min-width: var(--wh-98);
    height: 24px !important;

    /* 新建电站加号图标的定位问题 */
    >span.anticon.anticon-plus {
        margin-top: -2px;
    }
    >span.ant-typography {
       line-height: 1 !important;
    }

    &:hover {
      border-radius: 24px !important;
      border-color: var(--sky-585) !important;
	  box-shadow: 0 0 0 4px var(--sky-light-285) !important;

      color: var(--sky-55) !important;

      background: var(--white-100);
    }
  }
`