import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'

import { Row, Col, Space, Modal, Form, Input, Typography, Divider } from 'antd'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { UserInfoWrapper } from './style'

import { ResetIconOperation } from '../../../../operation'
import { Success, Error } from '../../../../notification'
import { updateUserInfo } from '../../../../../request/api'
import { valiTelephone, valiUserEmail,valiIsRepeatEmail } from '../../../../../utils/validator'

const UserInfo = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false)

  const [userInfoForm] = Form.useForm();

  userInfoForm.setFieldsValue({ 'userInfoForm': props.userInfo })

  //修改用户信息
  const handleUpdateUserInfo = () => {
    userInfoForm.validateFields().then(values => {
      setIsLoading(true);
      values.userInfoForm['userId'] = props.userInfo['userId']
      //console.info('userInfo userInfoForm',values.userInfoForm)
      updateUserInfo(values.userInfoForm).then(res => {
        try {
          if (res.success) {
            props.changeUserInfo(Math.floor(Math.random() * 999999))
            var sucModal = Modal.success();
            sucModal.update({
              icon: '',
              content: (
                <Success
                  messageTitle={t('Common.Header.userCenter.userInfo.Notification.Success.Title')}
                  messageSubTitle={''}
                  messageBtn={t('Common.Header.userCenter.userInfo.Notification.Success.btn')}
                  handleModel={sucModal}
                />
              ),
              okButtonProps: { style: { display: 'none' } },// 隐藏确认按钮
              cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
            });
          } else {
            switch (res.status) {
              case 630:
                var errModal = Modal.error();
                errModal.update({
                  icon: '',
                  content: (
                    <Error
                      messageTitle={t('Common.Header.userCenter.userInfo.Notification.Error.Title')}
                      messageSubTitle={t('Common.Header.userCenter.userInfo.Notification.Error.SubTitle')}
                      messageBtn={t('Common.Header.userCenter.userInfo.Notification.Error.btn')}
                      handleModel={errModal}
                    />
                  ),
                  okButtonProps: { style: { display: 'none' } },// 隐藏确认按钮
                  cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
                });
                break;
              default:
                break;
            }
          }
        } catch (err) {

        }
        setIsLoading(false);
        setIsOpen(false);
      })
    }).catch(err => {
      console.info('login err', err)
    })
  }
  useImperativeHandle(ref, () => ({
    handleOpen  
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOpen = () =>{
    setIsOpen(true);
  }

  //点击对应项重置信息
  const resetClickUser = (value,param)=>{
    var info = {}
    info['userInfoForm'] = {}
    info['userInfoForm'][param] = value[param] 
    userInfoForm.setFieldsValue(info)
  }
 
  return (
    <>
      <Modal
        className='ModalNormal ModalUserCenter'
        title={t('Common.Header.userCenter.userCenter.userInfo')}
        open={isOpen}
        onOk={handleUpdateUserInfo}
        onCancel={handleCancel}
        okText={t('All.Operation.submit')}
        cancelText={t('InterfaceMsg.502.btn')}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >

        <UserInfoWrapper>

          <Form
            className='EditForm'
            form={userInfoForm}
            hideRequiredMark
          >
            <Row gutter={32}>

              <Col xs={24} md={12}>
                <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                  <Typography.Text>{t('Login.Register.Input.Id.Title')}</Typography.Text>
                </Divider>
                <Form.Item
                  name={['userInfoForm', 'userCode']}
                  validateFirst={true}
                  validateTrigger={['onBlur']}

                >
                  <Input 
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                  <Space size={0}>
                    <Typography.Text>{t('Common.Header.userCenter.userInfo.Form.userName.label')}</Typography.Text>
                    <ResetIconOperation reductClick={()=>{resetClickUser(props.userInfo,'userName')}} confirm={false} />
                  </Space>
                  <Divider type='vertical' />
                  <Typography.Text type='success'>
                    <Space size={0}>
                      <InfoCircleOutlined />
                      {t('All.Tag.editable')}
                    </Space>
                  </Typography.Text>
                </Divider>
                <Form.Item
                  name={['userInfoForm', 'userName']}
                  validateFirst={true}
                  validateTrigger={['onBlur']}
                  rules={[
                    { required: true, message: t('Public.Explain.EmptyInput') },
                  ]}
                  hasFeedback
                >
                  <Input
                    autocomplete='off'
                    placeholder={t('Common.Header.userCenter.userInfo.Form.userName.placeholder')}
                    prefix={<EditOutlined />}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                  <Space size={0}>
                    <Typography.Text>{t('Common.Header.userCenter.userInfo.Form.userTelephone.label')}</Typography.Text>
                    <ResetIconOperation reductClick={()=>{resetClickUser(props.userInfo,'userTelephone')}} confirm={false} />
                  </Space>
                  <Divider type='vertical' />
                  <Typography.Text type='success'>
                    <Space size={0}>
                      <InfoCircleOutlined />
                      {t('All.Tag.editable')}
                    </Space>
                  </Typography.Text>
                </Divider>
                <Form.Item  
                  name={['userInfoForm', 'userTelephone']}
                  validateTrigger={['onBlur']}
                  validateFirst={true}
                  rules={[
                    { required: true, message: t('Public.Explain.EmptyInput') },
                    { validator: valiTelephone, message: t('Common.Header.userCenter.userInfo.Validator.userTelephone.valiTelephone') },//数値と特殊文字(-#*+()),Only number and (-#*+()) can be used
                  ]}
                  hasFeedback
                >
                  <Input 
                    autocomplete='off' 
                    placeholder={t('Login.Register.Input.Contact.Tips')}
                    prefix={<EditOutlined />}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                  <Space size={0}>
                    <Typography.Text>{t('Login.Register.Input.Email.Title')}</Typography.Text>
                    <ResetIconOperation reductClick={()=>{resetClickUser(props.userInfo,'userEmail')}} confirm={false} />
                  </Space>
                  <Divider type='vertical' />
                  <Typography.Text type='success'>
                    <Space size={0}>
                      <InfoCircleOutlined />
                      {t('All.Tag.editable')}
                    </Space>
                  </Typography.Text>
                </Divider>
                <Form.Item
                  name={['userInfoForm', 'userEmail']}
                  validateTrigger={['onBlur']}
                  validateFirst={true}
                  rules={[
                    { required: true, message: t('Public.Explain.EmptyInput') },
                    { validator: valiUserEmail, message: t('Common.Header.userCenter.userInfo.Validator.userEmail.valiUserEmail')}, //邮箱格式不正确,メールアドレスが一致しません,It's wrong format valiRepeatEmail
                    { validator: valiIsRepeatEmail, message: t('Login.Register.Validator.valiIsRepeatEmail') },//邮箱已被使用,メールが占有されています,E-mail is different
                  ]}
                  hasFeedback
                >
                  <Input 
                    autocomplete='off' 
                    placeholder={t('Login.Register.Input.Email.Tips')}
                    prefix={<EditOutlined />}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                  <Space size={0}>
                    <Typography.Text>{t('Common.Header.userCenter.userInfo.Form.userAddress.label')}</Typography.Text>
                    <ResetIconOperation reductClick={()=>{resetClickUser(props.userInfo,'userAddress')}} confirm={false} />
                  </Space>
                  <Divider type='vertical' />
                  <Typography.Text type='success'>
                    <Space size={0}>
                      <InfoCircleOutlined />
                      {t('All.Tag.editable')}
                    </Space>
                  </Typography.Text>
                </Divider>
                <Form.Item
                  name={['userInfoForm', 'userAddress']}
                  validateFirst={true}
                  validateTrigger={['onBlur']}
                  rules={[{ required: true, message: t('Public.Explain.EmptyInput') }]}
                  hasFeedback
                >
                  <Input
                    autocomplete='off'
                    placeholder={t('Common.Header.userCenter.userInfo.Form.userAddress.placeholder')}
                    prefix={<EditOutlined />}
                    maxLength={30}
                    showCount
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                  <Space size={0}>
                    <Typography.Text>{t('Login.Register.Input.CompanyInfo.Title')}</Typography.Text>
                    <ResetIconOperation reductClick={()=>{resetClickUser(props.userInfo,'companyName')}} confirm={false} />
                  </ Space>
                  <Divider type='vertical' />
                  <Typography.Text type='success'>
                    <Space size={0}>
                      <InfoCircleOutlined />
                      {t('All.Tag.editable')}
                    </Space>
                  </Typography.Text>
                </Divider>
                <Form.Item
                  name={['userInfoForm', 'companyName']}
                  validateTrigger={['onBlur', 'onSubmit']}
                  rules={[{ required: true, message: t('Public.Explain.EmptyInput') }]}
                  hasFeedback
                >
                  <Input 
                    autocomplete='off'
                    placeholder={t('Login.Register.Input.CompanyInfo.Tips')}
                    prefix={<EditOutlined />}
                    minLength={6} 
                    maxLength={50}
                    showCount
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </UserInfoWrapper>
      </Modal>
    </>
  )
})

const mapStateToProps = (state) => {
  return {
    userInfo: state.getIn(['header', 'userinfoList', 'user']),
    isLoad: state.getIn(['header', 'userinfoList', 'isLoad']),
  }
}

const mapDispathToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispathToProps, null, { forwardRef: true })(UserInfo)