import styled from 'styled-components'

export const RegisterPsInfoWrapper=styled.div`
    min-height: 254px;
    padding: 24px;

    .ant-picker-suffix {
        span.anticon {
            margin-right: 0;
        }
        span.ant-form-item-feedback-icon {
            display: none !important;
        }
    }

    @media (max-width: 576px) { 
        .ModalUserCenter .ant-modal-body,
        .ModalUserCenter .ant-typography,
        .ant-input-affix-wrapper-disabled .ant-input[disabled] {
            text-align: center !important;
        }
        .ant-select-selection-item {
            flex: none;
        }
    }

    .ant-picker {
        width: 100%;
    }

    .ant-input-affix-wrapper>.ant-input:not(textarea) {
        padding: 0 !important;
    }

    .ant-input-number,
    .ant-input-number-affix-wrapper {
        width: 100%;
        border-radius: 6px;
    }

    .ant-input-number-handler-wrap {
        border-radius: 0 6px 6px 0 !important;
    }

    .ant-input-number-handler-up {
        border-top-right-radius: 6px !important;
    }

    .ant-input-number-handler-down {
        border-bottom-right-radius: 6px !important;
    }

    .ant-input-number-disabled,
    .ant-input-number-affix-wrapper-disabled,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: var(--font-35);
        background-color: rgb(253 253 253 / 100%);
        border-color: rgb(240 240 240 / 100%);
    }
`