import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'

import { Space, Modal, Form, Input, Divider, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import IconSvg from '../../../../IconSvg';
import { UpdatePasswordWrapper } from './style'

import { Success, Error } from '../../../../notification'
import { valiPassword, valiPWMin, valiPWMax } from '../../../../../utils/validator'
import { updatePwd } from '../../../../../request/api'

const UpdatePassword = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false)

  const [updatePasswordForm] = Form.useForm();

  updatePasswordForm.setFieldsValue({ 'updatePasswordForm': {
      oldPassword:'',
      newPassword:'',
      resetPsw:'',
  }})

  useImperativeHandle(ref, () => ({
    handleOpen
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOpen = () =>{
    setIsOpen(true);
  }

  const handleUpdatePassword = () => {
    updatePasswordForm.validateFields().then(values => {
      setIsLoading(true);
      values.updatePasswordForm['userId'] = props.userInfo.userId;
      updatePwd(values.updatePasswordForm).then(res => {
        if (res.success) {
          setIsOpen(false);
          var sucModal = Modal.success();
          sucModal.update({
            icon: '',
            content: (
              <Success
                messageTitle={t('Common.Header.userCenter.updatePassword.Notification.Success.Title')}
                messageSubTitle={''}
                messageBtn={t('Common.Header.userCenter.updatePassword.Notification.Success.btn')}
                handleModel={sucModal}
              />
            ),
            okButtonProps: { style: { display: 'none' } },// 隐藏确认按钮
            cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
          });
        } else {
          switch (res.status) {
            case 510:
              var errModal = Modal.error();
              errModal.update({
                icon: '',
                content: (
                  <Error
                    messageTitle={t('Common.Header.userCenter.updatePassword.Notification.Error.Title')}
                    messageSubTitle={t('Common.Header.userCenter.updatePassword.Notification.Error.SubTitle')}
                    messageBtn={t('Common.Header.userCenter.updatePassword.Notification.Error.btn')}
                    handleModel={errModal}
                  />
                ),
                okButtonProps: { style: { display: 'none' } },// 隐藏确认按钮
                cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
              });
              break;
            default:
              break;
          }
        }
        setIsLoading(false);
      })
    }).catch(err => {
  
      console.info('login err', err)
    })
  }
  return (
    <>
        <Modal
          className='ModalSmall ModalUserCenter DividerCenterSM'
          title={t('Common.Header.userCenter.userCenter.updatePassword')}
          open={isOpen}
          onOk={handleUpdatePassword}
          onCancel={handleCancel}
          okText={t('All.Operation.submit')}
          cancelText={t('InterfaceMsg.502.btn')}
          okButtonProps={{ loading: isLoading }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          
          <UpdatePasswordWrapper>
            <Form 
              layout='vertical'
              form={updatePasswordForm}
            // onFinish = {handleUpdatePassword}
            >
              <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                <Typography.Text>{t('Common.Header.userCenter.updatePassword.Form.oldPassword.label')}</Typography.Text>
                <Divider type='vertical' />
                <Typography.Text type='secondary'>
                  <Space size={0}>
                    <InfoCircleOutlined />
                    {t('Common.Header.userCenter.updatePassword.Form.oldPassword.text')}
                  </Space>
                </Typography.Text>
              </Divider>

              <Form.Item
                name={['updatePasswordForm', 'oldPassword']}
                // label={t('Common.Header.userCenter.updatePassword.Form.oldPassword.label')}
                validateFirst={true}
                validateTrigger={['onBlur','onSubmit']}
                rules={[
                  { required: true, message: t('Public.Explain.EmptyInput') },
                  { validator: valiPWMin, message: t('Common.Header.userCenter.updatePassword.Validator.oldPassword.valiPWMin') },//8文字以上,PWD can not be less than 8 characters
                  { validator: valiPWMax, message: t('Common.Header.userCenter.updatePassword.Validator.oldPassword.valiPWMax') },//50文字以下,PWD can not be greater than 50 characters
                  { validator: valiPassword, message: t('Common.Header.userCenter.updatePassword.Validator.oldPassword.valiPassword') },//1文字と数字は必須です,PWD includes at least 1etter,1 number
                ]}
                hasFeedback
              >
                <Input.Password
                  minLength={6} 
                  maxLength={25} 
                  placeholder={t('Login.Register.Input.Password.Tips')} 
                  prefix={<IconSvg icon='fad fa-unlock-alt' size='small' />}
                  visibilityToggle
                  autocomplete='off'
                />
              </Form.Item>

              <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                <Typography.Text>{t('Login.Register.Input.Password.Title')}</Typography.Text>
                <Divider type='vertical' />
                <Typography.Text type='secondary'>
                  <Space size={0}>
                    <InfoCircleOutlined />
                    {t('Common.Header.userCenter.updatePassword.Form.password.text')}
                  </Space>
                </Typography.Text>
              </Divider>

              <Form.Item
                name={['updatePasswordForm', 'newPassword']}
                // label={t('Login.Register.Input.Password.Title')}
                validateFirst={true}
                validateTrigger={['onBlur','onSubmit']}
                rules={[
                  { required: true, message: t('Public.Explain.EmptyInput') },
                  { validator: valiPWMin, message: t('Common.Header.userCenter.updatePassword.Validator.newPassword.valiPWMin') },//8文字以上,PWD can not be less than 8 characters
                  { validator: valiPWMax, message: t('Common.Header.userCenter.updatePassword.Validator.newPassword.valiPWMax') },//50文字以下,PWD can not be greater than 50 characters
                  { validator: valiPassword, message: t('Common.Header.userCenter.updatePassword.Validator.newPassword.valiPassword') },//1文字と数字は必須です,PWD includes at least 1etter,1 number
                ]}
                hasFeedback
              >
                <Input.Password
                  minLength={6} 
                  maxLength={25} 
                  placeholder={t('Login.Register.Input.Password.Tips')} 
                  prefix={<IconSvg icon='fad fa-lock-open-alt' size='small' />}
                  visibilityToggle
                  autocomplete='off'
                />
              </Form.Item>

              <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
                <Typography.Text>{t('Login.Register.Input.ConfirmPassword.Title')}</Typography.Text>
                <Divider type='vertical' />
                <Typography.Text type='secondary'>
                  <Space size={0}>
                    <InfoCircleOutlined />
                    {t('Common.Header.userCenter.updatePassword.Form.repeatPassword.text')}
                  </Space>
                </Typography.Text>              
              </Divider>

              <Form.Item
                name={['updatePasswordForm', 'resetPsw']}
                // label={t('Login.Register.Input.ConfirmPassword.Title')}
                validateFirst={true}
                validateTrigger={['onBlur','onSubmit']}
                rules={[
                  { required: true, message: t('Public.Explain.EmptyInput') },
                  ({ getFieldValue }) => ({
                    validator (_, value) {
                      if (!value || getFieldValue('updatePasswordForm').newPassword === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('Common.Header.userCenter.updatePassword.Validator.resetPsw.resolve'));//两次邮箱不一致!,パスワードは一致していません,PWD is different
                    }
                  }),
                ]}
                hasFeedback
                dependencies={updatePasswordForm.newPassword}
              >
                <Input.Password
                  minLength={6}
                  maxLength={25}
                  placeholder={t('Login.Register.Input.ConfirmPassword.Tips')} 
                  prefix={<IconSvg icon='fad fa-lock-alt' size='small' />}
                  visibilityToggle
                  autocomplete='off'
                />
              </Form.Item>

            </Form>

          </UpdatePasswordWrapper>

        </Modal>   
    </>
  )
})

const mapStateToProps = (state) => {
  return {
    userInfo: state.getIn(['header', 'userinfoList', 'user'])
  }
}

const mapDispathToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispathToProps, null, { forwardRef: true })(UpdatePassword)