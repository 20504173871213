import styled from 'styled-components'

export const BackToTopIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  right: 3px;
  bottom: 16px;
  position: fixed;
  z-index: 5;

  border-radius: 50%;

  width: 40px;
  height: 40px;

  opacity: 0;

  .ant-btn {
    border: none;
    border-radius: 50%;
    box-sizing: border-box;

    color: var(--white-55);
    font-size: var(--fontsize-24);

    width: 40px;
    min-width: 0;
    height: 40px;

    transition: var(--transition-normal);

    &:hover {
      color: var(--white-100);
      box-shadow: inset 0 0 6px var(--white-100);
    }
  }
`

export const DelectIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  .ant-btn,
  .ant-btn .icon-svg {
    padding: 0;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    fill: var(--tomato-25);
    width: 14px;
    min-width: 14px;
    height: 16px;
  }
  .ant-btn:hover,
  .ant-btn:hover .icon-svg {
    fill: var(--tomato-100);
  }
  .ant-btn[disabled],
  .ant-btn[disabled] .icon-svg {
    fill: var(--tomato-15);
  }
  
  // 作为图标使用时 帮助文档配图说明
  &.asIcon {
    .ant-btn {
      &:hover,
      &:hover .icon-svg {
        cursor: default;
        fill: var(--tomato-25);
      }
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation:none 0 ease 0 1 normal;
  }
`

export const EditIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  .ant-btn,
  .ant-btn .icon-svg {
    padding: 0;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    fill: var(--sky-45);
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  .ant-btn {
    &:hover,
    &:hover .icon-svg {
      fill: var(--sky-100);
    }
  }

  .ant-btn[disabled],
  .ant-btn[disabled] .icon-svg {
    fill: var(--sky-15);
  }

  // 作为图标使用时 帮助文档配图说明
  &.asIcon {
    .ant-btn {
      &:hover,
      &:hover .icon-svg {
        cursor: default;
        fill: var(--sky-45);
      }
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const TransformIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  .ant-btn,
  .ant-btn .icon-svg {
    padding: 0;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    fill: var(--violet-45);
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
  .ant-btn:hover,
  .ant-btn:hover .icon-svg {
    fill: var(--violet-100);
  }
  .ant-btn[disabled],
  .ant-btn[disabled] .icon-svg {
    fill: var(--violet-15);
  }
  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const ResetIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  .ant-btn {
    padding: 0;
    border: none !important;
    border-color: transparent !important;
    box-shadow: 0 0 12px var(--sky-0);
    background: var(--white-0);
    color: var(--sky-35);

    &:hover {
      background: var(--white-100);
      box-shadow: 0 0 12px var(--sky-75);
      color: var(--sky-100);

      /* 还原初始值图标动态效果 */
      .anticon.anticon-history {
        animation: loadingCircle 1s infinite linear;
      }
      @keyframes loadingCircle {
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &.ant-btn-sm {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }

    &.ant-btn-lg {
      margin-left: 8px;
      width: 22px;
      min-width: 22px;
      height: 22px;
    }

    .icon-svg {
      fill: var(--sky-35);
      color: var(--sky-35);
    }
  }

  // 作为图标使用时 帮助文档配图说明
  &.asIcon {
    .ant-btn {
      &:hover,
      &:hover .icon-svg {
        cursor: default;
        box-shadow: 0 0 12px var(--sky-0);
        background: var(--white-0);
        color: var(--sky-35);
      }
      .anticon.anticon-history {
        animation: loadingCircle 0s infinite linear;
      }
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const ChartIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  .ant-btn,
  .ant-btn .icon-svg {
    padding: 0;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    fill: var(--sky-45);
    color: var(--sky-45);
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
  .ant-btn:hover,
  .ant-btn:hover .icon-svg {
    fill: var(--sky-100);
    color: var(--sky-100)
  }
  .ant-btn[disabled],
  .ant-btn[disabled] .icon-svg {
    fill: var(--sky-15);
    color: var(--sky-15)
  }
  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const SetIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .ant-btn {
    &:hover {
      /* 还原初始值图标动态效果 */
      .anticon.anticon-setting {
        animation: loadingCircle 1s infinite linear;
      }
      @keyframes loadingCircle {
        100% {
          transform: rotate(-360deg);
        }
      }
    }
  }
  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const SyncWrapper=styled.div`
  .ant-btn {
    &.ant-btn-round {
      border-radius: 32px !important;
      height: 32px;
      &.ant-btn-large {
        border-radius: 42px !important;
        height: 42px;
      }
    }
  }

  // 作为图标使用时 帮助文档配图说明
  &.asIcon {
    .ant-btn.ant-btn-round {
      &:hover {
        cursor: default;
        border-color: #1890ff;
        background: #1890ff;
      }
    }
  }
`

export const SaveIconWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .ant-input-suffix .ant-btn {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  &.saveInBlue {
    .ant-btn {
      padding: 0 !important;
      margin: -1px -14px 0 0;

      border-color: transparent !important;
      background: transparent !important;

      width: 14px;
      height: 14px;
      min-width: 14px !important;

      >.anticon.anticon-save {
        width: 14px;
        height: 14px;
        transition: var(--transition-normal);
        opacity: 0.35;
      }
      &:hover {
        >.anticon.anticon-save {
          transform: var(--size-xxx-larger);
          opacity: 1;
        }
      }
    }
  }

  &.saveInWhite {
    .ant-btn {
      margin: -1px -4px 0 0;
      padding: 0 !important;

      border-color: transparent !important;
      background: transparent !important;
      box-shadow: none;

      color: var(--sky-100);

      width: 14px;
      min-width: 14px !important;
      height: 14px;

      >.anticon.anticon-save {
        width: 14px;
        height: 14px;
        transition: var(--transition-normal);
        opacity: 0.55;
      }
      &:hover {
        >.anticon.anticon-save {
          transform: var(--size-xxx-larger);
          opacity: 1;
        }
      }
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const DetailWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    
  .ant-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    margin: 0;
    padding: 0;
    box-sizing: border-box;

    border: 1px solid var(--sky-0);
    border-radius: 24px !important;
    background: transparent;
    box-shadow: none;

    color: var(--sky-100);

    min-width: var(--wh-68);
    height: var(--wh-24);

    &:hover {
      border: 1px solid var(--sky-25);
      background: var(--white-100);
      box-shadow: 0 0 8px var(--sky-5);

      color: var(--sky-55);
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const CopyWrapper=styled.div`
  .ant-typography {
    display: flex;
    flex-direction: row;
    align-items: center;

    >.ant-typography-copy {
      width: 14px;
      height: 14px;
      >.anticon.anticon-copy {
        color: var(--sky-25);
        fill: var(--sky-25);
        transition: var(--transition-faster);
        &:hover {
          color: var(--sky-100);
          fill: var(--sky-100);
          transform: var(--size-large);
        }
      }
    }
  }
`

export const LinkTextWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    
  .ant-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    margin: 0;
    padding: 0 12px;
    box-sizing: border-box;

    border: 1px solid var(--sky-0);
    border-radius: 24px !important;
    background: transparent;
    box-shadow: none;

    color: var(--sky-100);

    min-width: var(--wh-68);
    height: var(--wh-24);

    &:hover {
      border: 1px solid var(--sky-25);
      background: var(--white-100);
      box-shadow: 0 0 8px var(--sky-5);

      color: var(--sky-55);
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const BlockWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .ant-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    margin: 0;
    padding: 0;
    box-sizing: border-box;

    border: 1px solid var(--white-100);
    border-radius: 24px !important;

    background: var(--sky-100);
    box-shadow: none;

    color: var(--white-100);
    font-size:  var(--fontsize-12);
    line-height: 1;
    letter-spacing: var(--spacing-large);

    width: calc(var(--wh-98) - var(--spacing-large));
    height: 24px;

    &:hover {
      border: 1px solid var(--white-100);
      background: var(--sky-btnhover);
      box-shadow: 0 0 8px var(--white-100);
      color: var(--white-100);
    }

    &[disabled],
    &[disabled]:hover {
      border-color: var(--sky-light-485);
      background: var(--sky-light-185);
      box-shadow: 0 2px 0 var(--sky-0);
      color: var(--sky-25);
      transition: var(--transition-normal);
    }

    &[disabled]>.icon-svg,
    &[disabled]>.anticon,
    &[disabled]:hover>.icon-svg,
    &[disabled]:hover>.anticon {
      fill: var(--sky-25);
      color: var(--sky-25);
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const UnblockWrapper=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .ant-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    margin: 0;
    padding: 0;
    box-sizing: border-box;

    border: 1px solid var(--white-100);
    border-radius: 24px !important;

    background: var(--sky-100);
    box-shadow: none;

    color: var(--white-100);
    font-size:  var(--fontsize-12);
    line-height: 1;
    letter-spacing: var(--spacing-large);

    width: calc(var(--wh-98) - var(--spacing-large));
    height: 24px;

    &:hover {
      border: 1px solid var(--white-100);
      background: var(--sky-btnhover);
      box-shadow: 0 0 8px var(--white-100);
      color: var(--white-100);
    }

    &[disabled],
    &[disabled]:hover {
      border-color: var(--sky-light-485);
      background: var(--sky-light-185);
      box-shadow: 0 2px 0 var(--sky-0);
      color: var(--sky-25);
      transition: var(--transition-normal);
    }

    &[disabled]>.icon-svg,
    &[disabled]>.anticon,
    &[disabled]:hover>.icon-svg,
    &[disabled]:hover>.anticon {
      fill: var(--sky-25);
      color: var(--sky-25);
    }
  }

  // 去除按钮点击后的涟漪动画效果
  .ant-btn[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
`

export const ListWrapper=styled.div`
`

export const AddWrapper=styled.div`
  // 作为图标使用时 帮助文档配图说明
  &.asIcon {
    .ant-btn.ant-btn-asIcon,
    .ant-btn.ant-btn-asIcon:hover, {
        cursor: default;
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        background: #fff;
        border-style: dashed;
    }
  }
`

export const EditWrapper=styled.div`
  // 作为图标使用时 帮助文档配图说明
  &.asIcon {
    .ant-btn.ant-btn-asIcon,
    .ant-btn.ant-btn-asIcon:hover, {
        cursor: default;
        color: rgba(0, 0, 0, 0.85);
        border-color: #d9d9d9;
        background: #fff;
        border-style: dashed;
    }
  }
`